import React from 'react';
import styled from 'styled-components';

const SideCardContainer = styled.aside`
    width: 30vw;
    height: 100vh;
    background-color: "#121212";
    color: white;
`;

export const SideCard: React.FC = () => {
    return (
        <SideCardContainer>
            Hey
        </SideCardContainer>
    )
}