import React from 'react';
import { ThemeProvider as TP } from 'styled-components';
import create from 'zustand';

export interface ThemeState {
    theme: ThemeType;
    colors: ColorTheme;
    setDarkTheme: ()=>void;
}

export type ThemeType = 'light' | 'dark';

export interface ColorTheme {
    bgPrimary: string;
    bgSecondary: string;
}

const darkTheme: ColorTheme = {
    bgPrimary: "#121212",
    bgSecondary: "#1D1D1D",
};

const lightTheme: ColorTheme = {
    bgPrimary: "#F3F4F6",
    bgSecondary: "#FFF",
}

export const useTheme = create<ThemeState>(set => ({
    theme: 'light',
    colors: lightTheme,
    setDarkTheme: () => set(s => ({ theme: 'dark', colors: darkTheme })),
}));

export const ThemeProvider: React.FC = ({ children }) => {
    const colors = useTheme(s => s.colors);

    const [width, setWidth] = React.useState(window.innerWidth);
  
    React.useEffect(() => {
      const handleWindowResize = () => setWidth(window.innerWidth);
      window.addEventListener("resize", handleWindowResize);
      return () => window.removeEventListener("resize", handleWindowResize);
    }, []);

    return (
        <TP theme={{ colors, isMobile: width <= 768 }}>
            { children }
        </TP>
    );
}