import React from 'react';
import { ThemeProvider } from '../../utils/theme';
import styled from 'styled-components';
import "./index.css";
import { SideCard } from './side-card';

const Wrapper = styled.div`
    width: 100vw;
    background-color: ${props => props.theme.colors.bgPrimary};
    display: flex;
    flex-direction: ${props => props.theme.isMobile ? 'column' : 'row'};
`;

const MainContent = styled.main`
    width: 70vw;
`

export const Layout: React.FC = ({ children }) => {
    return (
        <ThemeProvider>
            <Wrapper>
                <SideCard />
                <MainContent>
                { children }
                </MainContent>
            </Wrapper>
        </ThemeProvider>
    )
}

export default Layout;